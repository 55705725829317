import { combineReducers, createStore } from "redux"
import iconCodeReducer from "../reducers/iconCode"
import saveCodeReducer from "../reducers/saveCode"
import selectFile from "../reducers/selectFile"
import showDiagramReducer from "../reducers/showDiagram"
import sidebarShowReducer from "../reducers/sidebarToggle"
import editorReducer from "../reducers/editorReducer"
import classEditorReducer from "../reducers/classEditorReducer"
import ganttEditorReducer from "../reducers/ganttEditorReducer"
import brainstormEditorReducer from "../reducers/brainstormEditorReducer"
import protobufEditorReducer from "../reducers/protobufEditorReducer"
import avroSchemaEditorReducer from "../reducers/avroSchemaReducer"
import dockerComposeEditorReducer from "../reducers/dockerComposeEditorReducer"
import mindmapEditorReducer from "../reducers/mindmapEditorReducer"
import architectureEditorReducer from "../reducers/ArchitectureEditorReducer"
import stateReducer from "../reducers/StateReducer"
import archGoEditorReducer from "../reducers/archGoEditorReducer"
import terraformEditorReducer from "../reducers/terraformEditorReducer"
import mathEqEditorReducer from "../reducers/mathEqEditorReducer"
import playbookGrapherEditorReducer from "../reducers/playbookGrapherEditorReducer"
import inventoryGrapherEditorReducer from "../reducers/inventoryGrapherEditorReducer"
import asciiEditorReducer from "../reducers/AsciiReducer"
import blockEditorReducer from "../reducers/blockEditorReducer"
import actEditorReducer from "../reducers/ActEditorReducer"
import geojsonReducer from "../reducers/GeoJsonReducer"
import topojsonReducer from "../reducers/TopoJsonReducer"



const configureStore = () => {
  const store = createStore(
    combineReducers({
      sidebarShow: sidebarShowReducer,
      iconCode: iconCodeReducer,
      saveCode: saveCodeReducer,
      selectFile: selectFile,
      showDiagram: showDiagramReducer,
      editor: editorReducer,
      classEditor: classEditorReducer,
      ganttEditor: ganttEditorReducer,
      brainstormEditor: brainstormEditorReducer,
      playbookGrapher: playbookGrapherEditorReducer,
      inventoryGrapher: inventoryGrapherEditorReducer,
      protobufEditor: protobufEditorReducer,
      avroSchemaEditor: avroSchemaEditorReducer,
      dockerComposeEditor: dockerComposeEditorReducer,
      mindmapEditor: mindmapEditorReducer,
      architectureEditor: architectureEditorReducer,
      archGoEditor: archGoEditorReducer,
      mathEqEditor: mathEqEditorReducer,
      terraformEditor: terraformEditorReducer,
      stateReducer: stateReducer,
      asciiEditor: asciiEditorReducer,
      blockEditor: blockEditorReducer,
      actEditor: actEditorReducer,
      geoEditor: geojsonReducer,
      topoEditor: topojsonReducer,
    }),
  )
  return store
}

export default configureStore
