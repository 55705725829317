import { Alert, Button, CircularProgress, MenuItem, Select, Snackbar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { toggleDiagram } from 'src/redux/actions/showDiagram'
import { CONSTANTS } from '../Constants'
import { SequenceDiagram } from 'svg-sequence-diagram'
import { getWebSeqPNG } from '../diagrams/WebSequence'
import FlowChartDiagram from '../diagrams/FlowChartDiagram'
import ClassDiagram from '../diagrams/ClassDiagram'
import GanttChart from '../diagrams/GanttChart'
import DownloadIcon from '@mui/icons-material/Download'
import ShareIcon from '@mui/icons-material/Share'
import Brainstorm from '../diagrams/Brainstorm'
import Protobuf from '../diagrams/Protobuf'
import AvroSchema from '../diagrams/AvroSchema'
import DockerCompose from '../diagrams/DockerCompose'
import Mindmap from '../diagrams/Mindmap'
import ArchitectureDiagram from '../diagrams/ArchitectureDiagram'
import { useSelector } from 'react-redux'
import LeafletMap from '../LeafletMap'
import * as d3 from 'd3'
import html2canvas from 'html2canvas'
import ZoomButtons from '../ZoomButtons'
import ArchGo from '../diagrams/ArchGo'
import D2 from '../diagrams/D2'
import Terraform from '../diagrams/Terraform'
import DownloadModal from '../modals/DownloadModal'
import MathEquation from '../diagrams/MathEquation'
import PlaybookGrapher from '../diagrams/PlaybookGrapher'
import InventoryGrapher from '../diagrams/InventoryGrapher'
import UndoButton from './UndoButton'
import AsciiDiagram from '../diagrams/Ascii'
import BlockDiagram from '../diagrams/BlockDiagram'
import ActDiagram from '../diagrams/ActDiagram'
import GeoMapView from '../diagrams/Geojson'
import TopoMapView from '../diagrams/Topojson'


const DiagramSide = ({
  monacoRef,
  imageSrc,
  setSnackbarMessage,
  setOpenSnackbar,
  setSnackbarSeverity,
  setDirection,
  direction,
  dispatch,
  visibleLoading,
  setSelectedEmail,
  setVisibleShare,
  handleExecute,
  setVisibleLoading,
  setPythonError,
  archDiagram,
  setOpenSnackbarDiagram,
  isAuthenticated,
  opemSnackbarDiagram,
}) => {
  const [viewDiagramBtn, setViewDiagramBtn] = useState(false)
  const [visibleDownloading, setVisibleDownloading] = useState(false)
  const [diagramName, setDiagramName] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [imageWidth, setImageWidth] = useState('80%')
  const [imageHeight, setImageHeight] = useState('80%')
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const panZoomRef = useRef(null)

  useEffect(() => {
    if (!opemSnackbarDiagram) {
      handleDownload(true)
    }
  }, [opemSnackbarDiagram])

  const handleZoomIn = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn()
    }
  }

  const handleZoomOut = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomOut()
    }
  }

  const handlesShareBtn = () => {
    if (!(localStorage.getItem('SELECTEDFILE')) || localStorage.getItem('SELECTEDFILE') === 'DEFAULTFILENAME') {
      setSnackbarMessage('Please save the file before sharing.')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }
    setSelectedEmail([])
    setVisibleShare(true)
  }

  let selectedMode = useSelector((state) => state.stateReducer.selectModeValue)

  async function handleClientDiagramDownload() {
    if (selectedMode === CONSTANTS.WEBSEQUENCE) {
      var code = monacoRef.current.getValue()

      if (!code || code === '') {
        code = '\n text right: "code2diagram.com"'
      }

      if (sessionStorage.getItem('isSubscribed') !== 'true') {
        code = code + '\n text right: "code2diagram.com"'
      }
      const myDiagram = new SequenceDiagram(code)
      try {
        const { url } = await myDiagram.getPNG({ resolution: 20 })
        const pngImage = await getWebSeqPNG(url)
        if (pngImage) download(pngImage)
        setTimeout(setVisibleDownloading, 2000, false)
      } catch (error) {
        console.error('Error generating sequence diagram:', error)
        setVisibleDownloading(false)
      }
      return
    }

    let clientImages
    if (selectedMode === CONSTANTS.FLOW_CHART) {
      clientImages = sessionStorage.getItem('DOWNLOAD_FLOWCHART_IMAGE')
      if (!clientImages) clientImages = localStorage.getItem('FLOWCHART_IMAGE')
      processImage(clientImages)
      return
    }
    if (selectedMode === CONSTANTS.GEOJSON) {
      const mapElement = document.querySelector('.leaflet-container'); // Select the Leaflet map container

      if (!mapElement) {
        console.error("Map container not found!");
        return;
      }

      // Wait for tiles to load before capturing
      setTimeout(() => {
        html2canvas(mapElement, { useCORS: true, logging: true }).then((canvas) => {
          if (!canvas) {
            console.error("Failed to capture map!");
            return;
          }

          const imageData = canvas.toDataURL("image/png"); // Convert to PNG format
          console.log("imageData", imageData);

          // Create a download link
          const link = document.createElement('a');
          link.href = imageData;
          link.download = `${diagramName}.png`; // Download as PNG
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          console.error("Error capturing map:", error);
        });
      }, 2000); // Wait 2 seconds to ensure tiles are loaded

      return;
    }    
    if (selectedMode === CONSTANTS.TOPOJSON) {
      const mapElement = document.querySelector('.leaflet-container'); // Select the Leaflet map container

      if (!mapElement) {
        console.error("Map container not found!");
        return;
      }

      // Wait for tiles to load before capturing
      setTimeout(() => {
        html2canvas(mapElement, { useCORS: true, logging: true }).then((canvas) => {
          if (!canvas) {
            console.error("Failed to capture map!");
            return;
          }

          const imageData = canvas.toDataURL("image/png"); // Convert to PNG format
          console.log("imageData", imageData);

          // Create a download link
          const link = document.createElement('a');
          link.href = imageData;
          link.download = `${diagramName}.png`; // Download as PNG
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          console.error("Error capturing map:", error);
        });
      }, 2000); // Wait 2 seconds to ensure tiles are loaded

      return;
    }    
    if (selectedMode === CONSTANTS.CLASS_DIAGRAM) {
      clientImages = sessionStorage.getItem('DOWNLOAD_CLASS_IMAGE')
      if (!clientImages) clientImages = localStorage.getItem('CLASS_DIAGRAM_IMAGE')
      processImage(clientImages)
      return
    }

    if (selectedMode === CONSTANTS.MATHEQUATION) {
      const mathEqImage = localStorage.getItem('MATH_EQ_SVG')
      const fileName = diagramName
      const downloadLink = document.createElement('a')
      downloadLink.href = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(mathEqImage)}`
      downloadLink.download = `${fileName}.svg`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      setVisibleDownloading(false)
      setDiagramName('')
      return
    }

    if (selectedMode === CONSTANTS.MINDMAP) {

      const svg = d3.select('#mindMapId').select('svg');

      if (sessionStorage.getItem('isSubscribed') !== 'true') {
        // Add watermark at top right
        svg
          .append('text')
          .attr('class', 'watermark')
          .attr('x', 260) // Position near the top-right corner
          .attr('y', 60)
          .attr('text-anchor', 'end')
          .style('font-size', '60px')
          .style('fill', 'lightgrey')
          .style('opacity', 0.5)
          .text('code2diagram')
      }

      // Step 3: Serialize the SVG to a string
      const svgNode = svg.node();
      const svgData = new XMLSerializer().serializeToString(svgNode);

      const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = diagramName + '.svg'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return
    }

    if (selectedMode === CONSTANTS.GANTT_CHART) {
      const chartContainer = document.querySelector('.content')
      if (!(chartContainer instanceof HTMLElement)) return

      chartContainer.style.backgroundColor = 'white'

      html2canvas(chartContainer).then((canvas) => {
        chartContainer.style.backgroundColor = ''

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        const imgData = canvas.toDataURL('image/png')

        const img = document.createElementNS('http://www.w3.org/2000/svg', 'image')
        img.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', imgData)
        img.setAttribute('width', canvas.width.toString())
        img.setAttribute('height', canvas.height.toString())
        svg.appendChild(img)

        svg.setAttribute('width', canvas.width.toString())
        svg.setAttribute('height', canvas.height.toString())

        const svgData = new XMLSerializer().serializeToString(svg)
        const blob = new Blob([svgData], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.download = diagramName + '.svg'
        link.href = url
        link.click()
        URL.revokeObjectURL(url)
      })
      return
    }
  }

  async function handleDownload(isExecuted, isButtonClicked) {

    if (selectedMode == '' || selectedMode == ' ') {
      selectedMode = localStorage.getItem('selectedMode')
    }

    if (isButtonClicked && (selectedMode === CONSTANTS.WEBSEQUENCE || selectedMode === CONSTANTS.FLOW_CHART || selectedMode === CONSTANTS.GEOJSON|| selectedMode === CONSTANTS.TOPOJSON ||
      selectedMode === CONSTANTS.CLASS_DIAGRAM || selectedMode === CONSTANTS.MATHEQUATION ||
      selectedMode === CONSTANTS.GANTT_CHART || selectedMode === CONSTANTS.MINDMAP)) {
      handleClientDiagramDownload()
      return
    }

    //Server Side Diagrams Download

    if (!isExecuted) {
      handleExecute(true, diagramName)
      sessionStorage.setItem('isDownload', 'true')
      return
    }
    if (!sessionStorage.getItem('isDownload') || sessionStorage.getItem('isDownload') !== 'true') {
      return
    }

    sessionStorage.setItem('isDownload', 'false')
    setVisibleDownloading(true)

    if (
      selectedMode === CONSTANTS.JSON ||
      selectedMode === CONSTANTS.YAML ||
      selectedMode === CONSTANTS.ASCII ||
      selectedMode === CONSTANTS.SWAGGER
    ) {
      let image
      if (selectedMode === CONSTANTS.SWAGGER) {
        image = localStorage.getItem('LASTGENERATEDIMAGE_SWAGGER')
      }
      if (selectedMode === CONSTANTS.JSON) {
        image = localStorage.getItem('LASTGENERATEDIMAGE_JSON')
      }
      if (selectedMode === CONSTANTS.YAML) {
        image = localStorage.getItem('LASTGENERATEDIMAGE_YAML')
      }
      if (selectedMode === CONSTANTS.ASCII) {
        image = localStorage.getItem('LASTGENERATEDIMAGE_ASCII')
      }

      processImage(image)
    } else if (selectedMode === CONSTANTS.ARCHITECTURE_DIAGRAM) {
      download(localStorage.getItem('LASTGENERATEDIMAGE'), 'png')
    } else if (selectedMode === CONSTANTS.BRAINSTORM) {
      const brainStormImage = `data:image/svg+xml;base64,${localStorage.getItem('BRAINSTORM_IMG')}`
      download(brainStormImage, 'svg')
    } else if (selectedMode === CONSTANTS.D2) {
      const d2Image = `data:image/svg+xml;base64,${localStorage.getItem('D2_IMG')}`
      download(d2Image, 'svg')
    } else if (selectedMode === CONSTANTS.PROTOBUF) {
      const protobufImage = `data:image/svg+xml;base64,${localStorage.getItem('PROTOBUF_IMG')}`
      download(protobufImage, 'svg')
    } else if (selectedMode === CONSTANTS.PLAYBOOK_GRAPHER) {
      const playbookGrapherImage = `data:image/svg+xml;base64,${localStorage.getItem(
        'PlaybookGrapher_IMG',
      )}`
      download(playbookGrapherImage, 'svg')
    } 
  
  else if(selectedMode === CONSTANTS.ASCIII){
    const acsiii = `${localStorage.getItem('ASCIII_IMG')}`
    download(acsiii, 'png')

  }

  else if (selectedMode === CONSTANTS.BLOCK) {
    const blockImage = `data:image/png;base64,${localStorage.getItem('Block_IMG')}`
    download(blockImage, 'png')
  }
  else if (selectedMode === CONSTANTS.ACT) {
    const actImage = `data:image/png;base64,${localStorage.getItem('Act_IMG')}`
    download(actImage, 'png')
  }
    
    else if (selectedMode === CONSTANTS.AVROSCHEMA) {
      const avroSchemaImage = `data:image/png;base64,${localStorage.getItem('AVROSCHEMA_IMG')}`
      download(avroSchemaImage, 'png')
    } else if (selectedMode === CONSTANTS.INVENTORY_GRAPHER) {
      const inventoryGrapherImage = `data:image/png;base64,${localStorage.getItem(
        'INVENTORY_IMG_DOWNLOAD',
      )}`
      download(inventoryGrapherImage, 'png')
    } else if (selectedMode === CONSTANTS.TERRAFORM) {
      const terraformImage = `data:image/png;base64,${localStorage.getItem('TERRAFORM_IMG')}`
      download(terraformImage, 'png')
    } else if (selectedMode === CONSTANTS.ARCHGO) {
      const archGoImage = `data:image/png;base64,${localStorage.getItem('ARCHGO_IMG')}`
      download(archGoImage, 'png')
    } else if (selectedMode === CONSTANTS.DOCKERCOMPOSE) {
      download(localStorage.getItem('DOCKER_COMPOSE_IMG'), 'png')
    } else {
      download(imageSrc)
    }
  }

  async function processImage(image) {
    if (!image) image = imageSrc
    if (image) {
      const blob = await fetch(image).then((response) => response.blob())
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${diagramName}.svg`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
    setVisibleDownloading(false)
    setDiagramName('')
  }

  const download = (imageUrl, type) => {
    const fileName = diagramName

    const downloadLink = document.createElement('a')
    downloadLink.href = imageUrl
    downloadLink.download = `${fileName}.${type ? type : 'png'}`

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    setVisibleDownloading(false)
    setDiagramName('')
  }

  const handleDownloadCnst = async () => {
    if (!diagramName) {
      setSnackbarMessage('Please enter a diagram name')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
      return
    }
    handleDownload(false, true)
    setShowPopup(false)
  }

  const handleDirectionChange = (e) => {
    const editorCode = monacoRef.current?.getValue();
    if (!editorCode || editorCode.trim() === '') {
      setIsDialogOpen(true); 
      return;
    }
    setDirection(e.target.value); 
  };

  const handleCloseSnackbar = () => {
    setIsDialogOpen(false);
  };

  const openPopup = () => {

    if (!isAuthenticated) {
      setSnackbarMessage('Please login to download')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }

    if (!monacoRef.current || !monacoRef.current.getValue()) {
      setSnackbarMessage('Editor is empty. Please add some code')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }
    setShowPopup(true)
  }

  console.log("selected mode", selectedMode);
  

  return (
    <div className="container-fluid" style={{ backgroundColor: 'white', padding: '0px', width: '100%', maxWidth: '100vw', overflow: 'hidden'
    }}>
      <div className="d-flex flex-column" style={{ height: 'calc(94vh)' }}>
        {' '}
        <div className="editor-menu" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', minWidth: '0', overflow: 'hidden',
        }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              className="expand-button-diagram"
              onClick={() => {
                dispatch(toggleDiagram())
                setViewDiagramBtn(!viewDiagramBtn)
              }}
            >
              {viewDiagramBtn ? (
                <svg
                  width="6"
                  height="24"
                  viewBox="0 0 6 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.27758 1.66546C4.1174 0.704397 3.28589 0 2.31157 0V0C1.094 0 0.160976 1.0821 0.340133 2.28641L1.6976 11.4114C1.75565 11.8017 1.75565 12.1983 1.6976 12.5886L0.340133 21.7136C0.160976 22.9179 1.094 24 2.31157 24V24C3.28589 24 4.1174 23.2956 4.27758 22.3345L5.8904 12.6576C5.96297 12.2222 5.96297 11.7778 5.8904 11.3424L4.27758 1.66546Z"
                    fill="#878787"
                  />
                </svg>
              ) : (
                <svg width="6" height="24" viewBox="0 0 6 24" fill="none">
                  <path
                    d="M1.72242 1.66546C1.8826 0.704397 2.71411 0 3.68843 0C4.906 0 5.83902 1.0821 5.65987 2.28641L4.3024 11.4114C4.24435 11.8017 4.24435 12.1983 4.3024 12.5886L5.65987 21.7136C5.83902 22.9179 4.906 24 3.68843 24C2.71411 24 1.8826 23.2956 1.72242 22.3345L0.109599 12.6576C0.0370336 12.2222 0.0370336 11.7778 0.109599 11.3424L1.72242 1.66546Z"
                    fill="#878787"
                  />
                </svg>
              )}
            </Button>

            {[
              CONSTANTS.FLOW_CHART,
              CONSTANTS.ARCHITECTURE_DIAGRAM,
              CONSTANTS.CLASS_DIAGRAM,
              CONSTANTS.ARCHGO,
            ].includes(selectedMode) && (
                <Select
                  className="icon-button"
                  value={direction}
                  size="small"
                  style={{ width: '170px', borderRadius: '3px' }}
                  onChange={handleDirectionChange}
                >
                  <MenuItem value="TB">Top-To-Bottom</MenuItem>
                  <MenuItem value="BT">Bottom-To-Top</MenuItem>
                  <MenuItem value="LR">Left-To-Right</MenuItem>
                  <MenuItem value="RL">Right-To-Left</MenuItem>
                </Select>
              )}
            <Snackbar
              open={isDialogOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Alert onClose={handleCloseSnackbar} severity="warning" variant="filled">
                The editor is empty. Please enter some code to execute.
              </Alert>
            </Snackbar>

          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'flex-start', maxWidth: '100%', minWidth: '0' }}>
            <Tooltip title="Download">
                    <Button className="icon-button download-button" style={{flexShrink: 1, minWidth: '40px', maxWidth: '100%', overflow: 'hidden',}} onClick={openPopup}>
                <DownloadIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Share">
              <Button
                className="icon-button"
                style={{
                  flexShrink: 1,
                  minWidth: '40px', 
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
                onClick={() => {
                  handlesShareBtn()
                }}
              >
                <ShareIcon fontSize="small" />
              </Button>
            </Tooltip>
          </div>
        </div>
        <DownloadModal
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          diagramName={diagramName}
          setDiagramName={setDiagramName}
          handleDownloadCnst={handleDownloadCnst}
        />
        {visibleLoading &&
          (CONSTANTS.ARCHITECTURE_DIAGRAM === selectedMode ||
            CONSTANTS.ARCHGO === selectedMode ||
            CONSTANTS.SWAGGER === selectedMode ||
            CONSTANTS.JSON === selectedMode ||
            CONSTANTS.YAML === selectedMode ||
            CONSTANTS.ASCII === selectedMode ||
            CONSTANTS.WEBSEQUENCE === selectedMode) && (
            <div className="alignMiddle">
              <CircularProgress />
              Executing...
            </div>
          )}
        {!visibleLoading &&
          (CONSTANTS.SWAGGER === selectedMode ||
            CONSTANTS.JSON === selectedMode ||
            CONSTANTS.YAML === selectedMode ||
            CONSTANTS.ASCII === selectedMode ||
            CONSTANTS.WEBSEQUENCE === selectedMode) && (
            <LeafletMap
              imageSrc={imageSrc}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              panZoomRef={panZoomRef}
            />
          )}
        {selectedMode === CONSTANTS.FLOW_CHART && (
          <FlowChartDiagram
            direction={direction}
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
          />
        )}

        {selectedMode === CONSTANTS.GEOJSON && (
          <GeoMapView
         
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
          />

        )}

        { selectedMode === CONSTANTS.TOPOJSON && (
          <TopoMapView
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
          
          />
        )}
        {selectedMode === CONSTANTS.ARCHITECTURE_DIAGRAM && (
          <ArchitectureDiagram
            diagramName={archDiagram}
            setVisibleLoading={setVisibleLoading}
            setImageWidth={setImageWidth}
            setImageHeight={setImageHeight}
            setPythonError={setPythonError}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            setOpenSnackbar={setOpenSnackbar}
            direction={direction}
            panZoomRef={panZoomRef}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
          />
        )}


{selectedMode === CONSTANTS.ASCIII && (
          <AsciiDiagram
          diagramName={archDiagram}
          direction={direction}
          setOpenSnackbar={setOpenSnackbar}
          setOpenSnackbarDiagram={setOpenSnackbarDiagram}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          panZoomRef={panZoomRef}
          />
        )}
        {selectedMode === CONSTANTS.ARCHGO && (
          <ArchGo
            diagramName={archDiagram}
            direction={direction}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            panZoomRef={panZoomRef}

          />
        )}
        {selectedMode === CONSTANTS.CLASS_DIAGRAM && (
          <ClassDiagram
            direction={direction}
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.GANTT_CHART && <GanttChart />}
        {selectedMode === CONSTANTS.BRAINSTORM && (
          <Brainstorm
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.D2 && (
          <D2
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.PLAYBOOK_GRAPHER && (
          <PlaybookGrapher
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.INVENTORY_GRAPHER && (
          <InventoryGrapher
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}

{selectedMode === CONSTANTS.BLOCK && (
          <BlockDiagram
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}

{selectedMode === CONSTANTS.ACT && (
          <ActDiagram
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}

        {selectedMode === CONSTANTS.PROTOBUF && (
          <Protobuf
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.TERRAFORM && (
          <Terraform
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.AVROSCHEMA && (
          <AvroSchema
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.DOCKERCOMPOSE && (
          <DockerCompose
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setOpenSnackbarDiagram={setOpenSnackbarDiagram}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.MINDMAP && (
          <Mindmap
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode === CONSTANTS.MATHEQUATION && (
          <MathEquation
            panZoomRef={panZoomRef}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {selectedMode != !visibleLoading && (
          <ZoomButtons handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
        )}

      </div>
    </div>
  )
}

export default DiagramSide