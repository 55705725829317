import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { CONSTANTS } from 'src/components/Constants'
import ShareModal from 'src/components/modals/ShareModal'
import {
  saveCodeFn,
  getTeamListFn,
  resendEmailFn,
  fetchUserFn,
  getFileListFn,
  showPaymentConfirmationFn,
  getSharedDiagramCode,
  genarateShareDiagramUrl,
  handleDiagramUrl,
} from 'src/views/dashboard/DashboardFunctions'
import C2DHelmet from 'src/components/appContent/C2DHelmet'
import AppContent from 'src/components/appContent/AppContent'
import Alert from 'src/components/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedMode } from 'src/redux/actions/StateAction'
import CompileAlert from 'src/components/CompileAlert'
import { Button, Snackbar } from '@mui/material'
import { AppHeader } from 'src/components'
import DefaultLayout from 'src/layout/DefaultLayout'

const Dashboard = ({
  monacoRef,
  handleUpgrade,
  user,
  isAuthenticated,
  isLoading,
  getAccessTokenSilently,
  loginWithRedirect,
}) => {
  const saveCode = useSelector((state) => state.saveCode)
  const iconCode = useSelector((state) => state.iconCode)
  const selectFile = useSelector((state) => state.selectFile)
  const showDiagram = useSelector((state) => state.showDiagram)
  let selectedMode = useSelector((state) => state.stateReducer.selectModeValue)

  const [tag, setTag] = useState([])
  const [history, setHistory] = useState([])
  const [teamList, setTeamList] = useState([])
  const [selectedEmail, setSelectedEmail] = useState([])
  const [codeByFileName, setCodeByFileName] = useState({})
  const [selectedOptionAuto, setSelectedOptionAuto] = useState(CONSTANTS.DEFAULTFILENAME)
  const [imageSrc, setImageSrc] = useState(
    '$https://res.cloudinary.com/djvyd8hea/image/upload/v1706165488/DefaultArchDiagram_ncic91.png',
  )
  const [showVerifyEmail, setVerifyEmail] = useState(false)
  const [showVerifyEmailAlert, setVerifyEmailAlert] = useState(true)
  const [visibleShare, setVisibleShare] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadThreshold, setUploadThreshold] = useState(0)
  const [teamName, setTeamName] = useState('')
  const [filename, setFilename] = useState('')
  const [diagramName, setDiagramName] = useState('')
  const [direction, setDirection] = useState('TB')
  const [showUpload, setShowUpload] = useState(false)
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(false)

  const [editorState, setEditorState] = useState(
    '#Node is an abstract concept that represents a single system component object. In below example Team is a node\nTeams("Teams")\n\n#Bundle allows you group (or clustering) the nodes in an isolated group. In below example ELB and EC2 are bundled.\nBundle("DB Cluster"): ELB("lb") >> EC2("web")\n\n#Edge is an object representing a connection between Nodes with some additional properties. In below example >> are edge.\nawsUser("User") >> Teams("Teams")',
  )
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [openSnackbarDiagram, setOpenSnackbarDiagram] = useState(false)
  const [generatedLink, setGeneratedLink] = useState('')
  const dispatch = useDispatch()

  function CopyFunction() {
    navigator.clipboard.writeText(generatedLink)
    setSnackbarMessage('Link copied successfully!')
    setSnackbarSeverity('success')
    setOpenSnackbar(true)
  }

  async function handleShareDiagramBtnClick() {
    const token = await getAccessTokenSilently()

    var code = monacoRef.current.getValue()
    if (!code.includes('REPLACEFILENAME'))
      code = code.replace('):', ', filename="REPLACEFILENAME"):')

    var userId = sessionStorage.getItem('userFetchedId')
    const data = {
      code: code,
      userId: userId,
      mode: selectedMode,
      base64Image: imageSrc,
    }
    setSnackbarMessage('Generating Link...')
    setSnackbarSeverity('info')
    setOpenSnackbar(true)

    genarateShareDiagramUrl(
      token,
      data,
      setSnackbarMessage,
      setSnackbarSeverity,
      setOpenSnackbar,
      function (resp) {
        setGeneratedLink(resp)
      },
    )
  }

  // const monacoRef = useRef(null)
  axios.defaults.withCredentials = true

  // let chatGptCode = localStorage.getItem("chatGPTCode");

  // useEffect(()=>{
  //   console.log("chatGptCode", chatGptCode, monacoRef, monacoRef.current);
    
  //   if(chatGptCode && chatGptCode.length > 0 && monacoRef && monacoRef.current) {
  //   console.log("chatGptCode inside if, ", chatGptCode);
    
  //     monacoRef.current.setValue(chatGptCode);
  //     localStorage.setItem("chatGPTCode", "");
  //   }
  // },[chatGptCode, monacoRef, monacoRef?.current])

  const handleSnackbarClose = () => {
    setOpenSnackbar(false)
  }
  const handleSnackbarCloseDiagram = () => {
    setOpenSnackbarDiagram(false)
  }

  function appendCode(value, range) {
    if (!monacoRef.current) return
    var id = { major: 1, minor: 1 }
    var text = `${value}`
    var op = {
      identifier: id,
      range: monacoRef.current.getSelection(),
      text: text,
      forceMoveMarkers: true,
    }
    if (range) {
      op.range = range
    }
    monacoRef.current.executeEdits('source-update', [op])
    monacoRef.current.pushUndoStop();
    monacoRef.current.focus();
  }

  async function getFileList(teamId, users) {
    const token = await getAccessTokenSilently()

    if (sessionStorage.getItem('userFetchedId') || teamId) {
      getFileListFn(teamId, users, token, setHistory, setCodeByFileName, function (result) {
        if (teamId) {
          if (result.length > 0) {
            monacoRef.current.executeEdits('', [
              { range: monacoRef.current.getModel().getFullModelRange(), text: '' },
            ])
            appendCode(result[0].code)
          } else appendCode('#Shared Code')
        }
      })
    }
  }

  useEffect(() => {
    const selectedFileName = localStorage.getItem('SELECTEDFILE')
    setSelectedOptionAuto(selectedFileName || CONSTANTS.DEFAULTTHEME)
  }, [])

  const handleOptionChange = (name) => {
    localStorage.setItem('SELECTEDFILE', name)
    setSelectedOptionAuto(name)
  }

  function selectedCode(file) {
    monacoRef.current.executeEdits('', [
      { range: monacoRef.current.getModel().getFullModelRange(), text: '' },
    ])
    if (file) appendCode(file.code)
  }

  function validateFilename(filename) {
    return /^[a-zA-Z0-9\s][a-zA-Z0-9\s._-]*$/.test(filename)
  }

  async function saveCodeApi(value, event, config) {
    var editorValue = monacoRef.current.getValue()
    if (!editorValue || editorValue === '' || /^[\n\t]+$/.test(editorValue)) {
      setSnackbarMessage('Empty Editor. Nothing to save!!!')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }

    if (!validateFilename(value)) {
      setSnackbarMessage(
        ' Invalid filename. Please make sure the filename starts with a letter (a-z or A-Z) or number (0-9)',
      )
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }
    sessionStorage.setItem('isFileListApiCalled2', 'null')
    setSnackbarMessage('Saving file...')
    setSnackbarSeverity('info')
    setOpenSnackbar(true)

    const token = await getAccessTokenSilently()
    var code = monacoRef.current.getValue()
    const codeCopy = code
    if (!code.includes('REPLACEFILENAME'))
      code = code.replace('):', ', filename="REPLACEFILENAME"):')

    var userId = sessionStorage.getItem('userFetchedId')
    const data = {
      code: code,
      userId: userId,
      fileName: value,
      orientation: direction,
      diagramName: diagramName,
      mode: selectedMode,
    }
    saveCodeFn(
      value,
      event,
      token,
      data,
      setSnackbarMessage,
      setSnackbarSeverity,
      setOpenSnackbar,
      function (response) {
        if (response.data.error != null) {
          setSnackbarMessage('Filename already exists!')
          setSnackbarSeverity('error')
          setOpenSnackbar(true)
        } else {
          handleOptionChange(value)
          if (!event || event !== 'UPDATE') {
            history.push(value)
            setHistory(history)
          }

          data.code = codeCopy
          data.id = response.data.result.id
          var fileListByFileName = codeByFileName
          fileListByFileName[value] = data
          setCodeByFileName(fileListByFileName)
          localStorage.setItem('selectedFileCode', JSON.stringify(fileListByFileName))

          localStorage.setItem("SELECTEDFILE", value)
          if (!config && event && event === 'UPDATE') {
            setSnackbarMessage('File Updated Successfully')
            setSnackbarSeverity('success')
            setOpenSnackbar(true)
          } else {
            setSelectedOptionAuto(value)
            setSnackbarMessage('File Saved Successfully')
            setSnackbarSeverity('success')
            setOpenSnackbar(true)
          }
        }
      },
    )
  }

  function handleTeamFile() {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get('teamId') && queryParams.get('members')) {
      if (queryParams.get('mode') === 'WEB') {
        localStorage.setItem('selectedMode', CONSTANTS.WEBSEQUENCE)
        dispatch(setSelectedMode(CONSTANTS.WEBSEQUENCE))
      } else if (queryParams.get('mode') === 'AD') {
        localStorage.setItem('selectedMode', CONSTANTS.ARCHITECTURE_DIAGRAM)
        dispatch(setSelectedMode(CONSTANTS.ARCHITECTURE_DIAGRAM))
      } else {
        localStorage.setItem('selectedMode', queryParams.get('mode'))
        dispatch(setSelectedMode(queryParams.get('mode')))
      }

      if (isAuthenticated) {
        var teamId = queryParams.get('teamId'),
          users = queryParams.get('members')
        setTimeout(getFileList, 1000, teamId, users)
      } else if (!queryParams.get('error')) {
        sessionStorage.setItem('redirectUrl', window.location.href)
        loginWithRedirect()
      }
    }
  }

  async function handleShareDiagram() {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get('shareId') && queryParams.get('diagram')) {
      localStorage.setItem('selectedMode', queryParams.get('mode'))
      dispatch(setSelectedMode(queryParams.get('mode')))

      if (isAuthenticated) {
        const token = await getAccessTokenSilently()
        setTimeout(
          getSharedDiagramCode,
          1000,
          token,
          appendCode,
          setImageSrc,
          monacoRef,
          queryParams.get('shareId'),
          queryParams.get('diagram'),
          setSnackbarMessage,
          setSnackbarSeverity,
          setOpenSnackbar,
          function (resp) {},
        )
      } else if (!queryParams.get('error')) {
        sessionStorage.setItem('redirectUrl', window.location.href)
        loginWithRedirect()
      }
    }
  }

  async function shareCodeToTeam(req, event) {

    // Check team name uniqueness
    const isDuplicateTeamName = teamList.some((team) => team.name === req.name)
    if (isDuplicateTeamName && selectedEmail.length === 0) {
      setSnackbarMessage('Team name already exists!')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }

    // Validate filename
    if (!validateFilename(req.fileName)) {
      setSnackbarMessage(
        'Invalid filename. Please make sure the filename starts with a letter (a-z or A-Z) or number (0-9).',
      )
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      return
    }

    // Trim and filter email tags
    const nonEmptyTags = tag.map((email) => email.trim()).filter((email) => email !== '')

    if (nonEmptyTags.length === 0 && selectedEmail.length === 0) {
      setSnackbarMessage('Please select a team or create a new team.')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
      setVisibleShare(true)
      return
    }
    setVisibleShare(false)

    req.emailList = nonEmptyTags

    if (selectedEmail.length > 0) {
      req.isExistingTeam = 1
      req.emailList = selectedEmail
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const invalidEmails = nonEmptyTags.filter((email) => !emailRegex.test(email))

    if (invalidEmails.length > 0) {
      const validEmails = nonEmptyTags.filter((email) => emailRegex.test(email))
      setTag(validEmails)

      if (validEmails.length === 0) {
        const removedEmailsMessage = `Removed invalid emails: ${invalidEmails.join(', ')}`
        setSnackbarMessage(`${removedEmailsMessage}. Please enter valid email addresses.`)
        setSnackbarSeverity('warning')
        setOpenSnackbar(true)
        setVisibleShare(true)
        return
      }
    }

    // Proceed with sharing code
    try {
      const token = await getAccessTokenSilently()
      let code = monacoRef.current.getValue()
      if (!code.includes('REPLACEFILENAME')) {
        req.code = code.replace('):', ', filename="REPLACEFILENAME"):')
      }
      const userId = sessionStorage.getItem('userFetchedId')
      req.createdBy = userId
      req.mode = selectedMode

      const response = await axios.post(
        `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/team/share`,
        req,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      getTeamList()
      setTag([])
      setSelectedEmail([])
      setSnackbarMessage('File Shared Successfully')
      setSnackbarSeverity('success')
      setOpenSnackbar(true)
    } catch (error) {
      console.error('Error:', error)
      setSnackbarMessage('Internal Server error')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
      setTag([])
      setSelectedEmail([])
    }
  }

  function handleSelectedTeam(selectedTeamId) {
    if (selectedTeamId === 'disabled') {
      setSelectedEmail([])
      return
    }

    for (var i = 0; i < teamList.length; i++) {
      if (teamList[i].teamId === selectedTeamId) {
        setSelectedEmail(teamList[i].memberEmails.split(','))
        break
      }
    }
    return
  }

  async function getTeamList() {
    const token = await getAccessTokenSilently()
    getTeamListFn(token, setTeamList, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar)
  }

  function resendEmail() {
    resendEmailFn(setVerifyEmail)
  }

  async function fetchUser() {
    const token = await getAccessTokenSilently()
    if (token && user) {
      fetchUserFn(token, user, function (resp) {
        if (resp) {
          getFileList()
          getTeamList()
        }
      })
    }
  }

  async function showPaymentConfirmation() {
    const token = await getAccessTokenSilently()
    showPaymentConfirmationFn(token, setShowPaymentConfirm)
  }

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        const storedRedirectUrl = sessionStorage.getItem('redirectUrl')
        if (storedRedirectUrl) {
          sessionStorage.removeItem('redirectUrl')
          window.location.replace(storedRedirectUrl)
        } else {
          handleTeamFile()
          handleShareDiagram()
        }
      } else {
        sessionStorage.removeItem('redirectUrl')
        handleTeamFile()
        handleShareDiagram()
      }
    }
  }, [isLoading])

  useEffect(() => {
    let storedMode = localStorage.getItem('selectedMode')
    if (storedMode !== '' && storedMode !== undefined) {
      dispatch(setSelectedMode(storedMode))
    } else {
      dispatch(setSelectedMode(CONSTANTS.FLOW_CHART))
    }

    if (isAuthenticated) {
      fetchUser()
      getFileList()
      getTeamList()
      showPaymentConfirmation()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  useEffect(() => {
    if (selectFile && selectFile.includes('FILESELECTEDFROMAPPHEADER')) {
      var filename = selectFile.split('_')[1]
      var codeObj = JSON.parse(localStorage.getItem('selectedFileCode'))
      selectedCode(codeObj[filename])
    } else if (saveCode && saveCode.includes('FILENAMETOBESAVED_')) {
      var filenameToSave = saveCode.split('_')[1]
      if (saveCode === 'SHOWLOGINREQUIRED' || saveCode === 'SHOWLOGINREQUIRED1') {
        setSnackbarMessage('Please login to save')
        setSnackbarSeverity('warning')
        setOpenSnackbar(true)
      } else if (saveCode === 'UPDATESELECTEDFILE' || saveCode === 'UPDATESELECTEDFILE1')
        saveCodeApi(selectedOptionAuto, 'UPDATE')
      else saveCodeApi(filenameToSave, null)
    } else if (iconCode) {
      var code = '\n' + iconCode
      if (selectedMode === CONSTANTS.WEBSEQUENCE) {
        if (code.includes('#EXAMPLEWEBSEQCODE')) code = code.replace('#EXAMPLEWEBSEQCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.FLOW_CHART) {
        if (code.includes('#EXAMPLEFLOWCHARTCODE')) code = code.replace('#EXAMPLEFLOWCHARTCODE', '')
        appendCode(code)
      } 
      else if (selectedMode === CONSTANTS.GEOJSON) {
        if (code.includes('#EXAMPLEGEOJSONCODE')) code = code.replace('#EXAMPLEGEOJSONCODE', '')
        appendCode(code)
      }   else if (selectedMode === CONSTANTS.TOPOJSON) {
        if (code.includes('#EXAMPLETOPOJSONCODE')) code = code.replace('#EXAMPLETOPOJSONCODE', '')
        appendCode(code)
      } 
      else if (selectedMode === CONSTANTS.SWAGGER) {
        if (code.includes('#EXAMPLESWAGGERCODE')) code = code.replace('#EXAMPLESWAGGERCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.JSON) {
        if (code.includes('#EXAMPLEJSONCODE')) code = code.replace('#EXAMPLEJSONCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.YAML) {
        if (code.includes('#EXAMPLEYAMLCODE')) code = code.replace('#EXAMPLEYAMLCODE', '')
        appendCode(code)
      }else if (selectedMode === CONSTANTS.ASCII) {
        if (code.includes('#EXAMPLEASCIICODE')) code = code.replace('#EXAMPLEASCIICODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.CLASS_DIAGRAM) {
        if (code.includes('#EXAMPLECLASSDIAGRAMCODE'))
          code = code.replace('#EXAMPLECLASSDIAGRAMCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.AVROSCHEMA) {
        if (code.includes('#EXAMPLEAVROSCHEMACODE'))
          code = code.replace('#EXAMPLEAVROSCHEMACODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.D2) {
        if (code.includes('#EXAMPLED2CODE')) code = code.replace('#EXAMPLED2CODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.ARCHGO) {
        if (code.includes('#EXAMPLEARCHGOCODE')) code = code.replace('#EXAMPLEARCHGOCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.TERRAFORM) {
        if (code.includes('#EXAMPLETERRAFORMCODE')) code = code.replace('#EXAMPLETERRAFORMCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.PROTOBUF) {
        if (code.includes('#EXAMPLEPROTOBUFCODE')) code = code.replace('#EXAMPLEPROTOBUFCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.DOCKERCOMPOSE) {
        if (code.includes('#EXAMPLEDOCKERCOMPOSECODE'))
          code = code.replace('#EXAMPLEDOCKERCOMPOSECODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.BRAINSTORM) {
        if (code.includes('#EXAMPLEBRAINSTORMCODE'))
          code = code.replace('#EXAMPLEBRAINSTORMCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.PLAYBOOK_GRAPHER) {
        if (code.includes('#EXAMPLEPLAYBOOKGRAPHERCODE'))
          code = code.replace('#EXAMPLEPLAYBOOKGRAPHERCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.MATHEQUATION) {
        if (code.includes('#EXAMPLEMATHEQUATIONCODE'))
          code = code.replace('#EXAMPLEMATHEQUATIONCODE', '')
        appendCode(code)
      } else if (selectedMode === CONSTANTS.MINDMAP) {
        if (code.includes(' #EXAMPLEMINDMAPCODE'))
          code = code.replace(' #EXAMPLEMINDMAPCODE', '')
        appendCode(code)
      } else if (iconCode.includes('Custom(')) {
        appendCode(code)
      } else if (
        iconCode.includes('with') ||
        iconCode.includes('Bundle') ||
        iconCode.includes('bundle') ||
        iconCode.includes('DIAGRAM_NAME') ||
        iconCode.includes('ORIENTATION') ||
        iconCode.includes('\n')
      ) {
        appendCode(code)
      } else appendCode(code)
    }
  }, [iconCode, saveCode])

  useEffect(() => {
    handleDiagramUrl(setSelectedMode)
    sessionStorage.setItem('SELECTEDFILE', CONSTANTS.DEFAULTFILENAME)
    sessionStorage.setItem('selectedMode', selectedMode)

    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get('error') && queryParams.get('error_description') && showVerifyEmailAlert) {
      var errorDescription = queryParams.get('error_description')
      sessionStorage.setItem(
        'VERIFY_EMAIL_USERID',
        errorDescription.split('----')[1].split('--')[1],
      )
      setVerifyEmailAlert(false)
      setVerifyEmail(true)
    }

    if (!localStorage.getItem('INTELLISENSEJSON')) {
      ;(async () => {
        const intellisenseJsonResp = await axios.get(
          `${process.env.REACT_APP_SIDEBAR_API_URL}/intellisense.json`,
        )
        localStorage.setItem('INTELLISENSEJSON', JSON.stringify(intellisenseJsonResp.data))
      })()
    }

    if (!localStorage.getItem('WEBSEQ_INTELLISENSEJSON')) {
      ;(async () => {
        const intellisenseJsonResp = await axios.get(
          `${process.env.REACT_APP_SIDEBAR_API_URL}/webseqIntellisense.json`,
        )
        localStorage.setItem('WEBSEQ_INTELLISENSEJSON', JSON.stringify(intellisenseJsonResp.data))
      })()
    }

    if (localStorage.getItem('LASTGENERATEDIMAGE')) {
      setImageSrc(localStorage.getItem('LASTGENERATEDIMAGE'))
    }

    if (localStorage.getItem('NEWFILE') === 'CLICKED') {
      setTimeout(setNewFileState, 2000, 'OPENED')
      setEditorState('')
    } else if (localStorage.getItem('EDITORSTATE')) {
      var defaultCode1 = localStorage.getItem('EDITORSTATE')
      defaultCode1 = addNextLine(defaultCode1, 100)
      if (!defaultCode1.includes('DIAGRAM_NAME')) setEditorState(defaultCode1)
    }
    if (sessionStorage.getItem('ORIENTATIONS')) {
      setDirection(sessionStorage.getItem('ORIENTATIONS'))
    }
  }, [])

  function addNextLine(text, n) {
    const emptyLines = text.match(/(\n\s*\n)+/g)
    if (emptyLines && emptyLines.length >= n) return text
    for (var i = 1; i < n; i++) {
      text = text + '\n'
    }
    return text
  }

  useEffect(() => {
    if (localStorage.getItem('NEWFILE') === 'CLICKED') {
      setTimeout(setNewFileState, 2000, 'OPENED')
      setEditorState('')
    } else if (localStorage.getItem('EDITORSTATE')) {
      var defaultCode1 = localStorage.getItem('EDITORSTATE')
      defaultCode1 = addNextLine(defaultCode1, 100)
      if (!defaultCode1.includes('DIAGRAM_NAME')) setEditorState(defaultCode1)
    }
  }, [!showDiagram])

  function setNewFileState(state) {
    localStorage.setItem('NEWFILE', state)
  }

  document.addEventListener('uploadBtnClickEvent', function (event) {
    event.stopPropagation()
    event.preventDefault()
    if (isAuthenticated) {
      var isSubscribed = sessionStorage.getItem('isSubscribed')
      var iconLength = sessionStorage.getItem('MYICONLENGTH')
      if(!iconLength) iconLength= "0";
      if (isSubscribed === 'true') {
        setUploadThreshold(200 - parseInt(iconLength))
      } else {
        setUploadThreshold(50 - parseInt(iconLength))
      }
      setUploading(false)
      setShowUpload(true)
    } else if (!isLoading) {
      setSnackbarMessage('Please login to upload')
      setSnackbarSeverity('warning')
      setOpenSnackbar(true)
    }
  })

  return (
    <>
      <C2DHelmet />

      <Snackbar
        open={showVerifyEmail}
        onClose={() => setVerifyEmail(false)}
        message="Please verify your email to log in securely."
        action={
          <Button color="inherit" size="small" onClick={resendEmail}>
            Resend Email
          </Button>
        }
      />
      <AppContent
        monacoRef={monacoRef}
        uploadThreshold={uploadThreshold}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setOpenSnackbar={setOpenSnackbar}
        setOpenSnackbarDiagram={setOpenSnackbarDiagram}
        getAccessTokenSilently={getAccessTokenSilently}
        setUploading={setUploading}
        setShowUpload={setShowUpload}
        handleOptionChange={handleOptionChange}
        selectedCode={selectedCode}
        codeByFileName={codeByFileName}
        diagramName={diagramName}
        setImageSrc={setImageSrc}
        direction={direction}
        isAuthenticated={isAuthenticated}
        saveCodeApi={saveCodeApi}
        selectedOptionAuto={selectedOptionAuto}
        appendCode={appendCode}
        imageSrc={imageSrc}
        setHistory={setHistory}
        history={history}
        setSelectedOptionAuto={setSelectedOptionAuto}
        setCodeByFileName={setCodeByFileName}
        showUpload={showUpload}
        uploading={uploading}
        handleUpgrade={handleUpgrade}
        showPaymentConfirm={showPaymentConfirm}
        setShowPaymentConfirm={setShowPaymentConfirm}
        filename={filename}
        setFilename={setFilename}
        setDirection={setDirection}
        setSelectedEmail={setSelectedEmail}
        editorState={editorState}
        setVisibleShare={setVisibleShare}
        openSnackbarDiagram ={openSnackbarDiagram}
      />
   
      <ShareModal
        visibleShare={visibleShare}
        setVisibleShare={setVisibleShare}
        setFilename={setFilename}
        handleSelectedTeam={handleSelectedTeam}
        selectedEmail={selectedEmail}
        teamName={teamName}
        setTeamName={setTeamName}
        shareCodeToTeam={shareCodeToTeam}
        filename={filename}
        direction={direction}
        diagramName={diagramName}
        teamList={teamList}
        tag={tag}
        setTag={setTag}
        generatedLink={generatedLink}
        CopyFunction={CopyFunction}
        handleShareDiagram={handleShareDiagramBtnClick}
        setGeneratedLink={setGeneratedLink}
        saveCodeApi={saveCodeApi}
        selectedOptionAuto={selectedOptionAuto}

      />
      <Alert
        openSnackbar={openSnackbar}
        handleSnackbarClose={handleSnackbarClose}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />

      <CompileAlert
        openSnackbarDiagram={openSnackbarDiagram}
        handleSnackbarCloseDiagram={handleSnackbarCloseDiagram}
      />
    </>
  )
}

export default Dashboard
