import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateAsciiiDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const AsciiDiagram = ({
  diagramName,
  direction,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
  panZoomRef,
}) => {
  // Accessing the ASCII editor value from Redux store
  const editorValue = useSelector((state) => state.asciiEditor.asciiEditorValue)
  const [asciiImage, setAsciiImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [previousCode, setPreviousCode] = useState('')  // Track previous editor value

  axios.defaults.withCredentials = true

   useEffect(() => {
      if (panZoomRef.current) {
        panZoomRef.current.setOptions({ preventScroll: true });
      }
    }, [panZoomRef]);

  useEffect(() => {
    const generateAsciiImage = async () => {
      try {
        if (!editorValue || typeof editorValue !== 'string') {
          setAsciiImage('')
          return
        }

        // Sanitize the editor input
        const sanitizedEditorValue = editorValue
          .replace(/\r\n/g, '\n')  
          .replace(/\r/g, '\n')    
          .trim()

        // If the code hasn't changed, skip generation
        if (sanitizedEditorValue === previousCode) return

        setPreviousCode(sanitizedEditorValue)  // Update previousCode state
        setLoading(true)
        setOpenSnackbarDiagram(true)

        // Generate the ASCII diagram
        const outputImage = await generateAsciiiDiagram(sanitizedEditorValue)

        if (outputImage && !outputImage.includes('failed to compile')) {
          setAsciiImage(outputImage)
          localStorage.setItem('ASCIII_IMG', outputImage)
          setLoading(false)
          setOpenSnackbarDiagram(false)
        } else {
          setLoading(false)
          setOpenSnackbar(true)
          setSnackbarMessage('Compilation failed. Invalid input format')
          setSnackbarSeverity('error')
          setOpenSnackbarDiagram(false)
        }
      } catch (error) {
        console.error('Error generating ascii diagram:', error)
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
      }
    }

    // Call the generate function if the editorValue changes and it's not empty
    if (editorValue.trim()) {
      generateAsciiImage()
    }

    // Set the selected mode in localStorage
    localStorage.setItem('selectedMode', CONSTANTS.ASCIII)
  }, [editorValue, previousCode, setOpenSnackbar, setOpenSnackbarDiagram, setSnackbarMessage, setSnackbarSeverity])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          {loading && (
            <div className="alignMiddle">
              <CircularProgress />
              Executing...
            </div>
          )}
          {!loading && (asciiImage ? (
            <img
              src={asciiImage.startsWith('data:image/png;base64,') ? asciiImage : `data:image/png;base64,${asciiImage}`}
              alt="ASCII Diagram"
              className="image-style"
            />
          ) : (
            <div style={{ color: 'gray', fontSize: '24px' }}>No Diagram Available</div>
          ))}
        </PanZoom>
      </MapContainer>
    </div>
  )
}

export default AsciiDiagram
