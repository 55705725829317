import { SET_TOPOJSON_EDITOR_VALUE } from "../actions/TopoJsonAction";

const localStorageKey = 'Topojson_diagram';

const storedTopojsonEditorValue = localStorage.getItem(localStorageKey);
const defaultTopojsonEditorValue =
storedTopojsonEditorValue ||
  `{
      "type": "Topology",
      "transform": {
        "scale": [0.0005000500050005, 0.00010001000100010001],
        "translate": [100, 0]
    },
    "objects": {
      "example": {
        "type": "GeometryCollection",
        "geometries": [
          {
            "type": "Point",
            "properties": {"prop0": "value0"},
            "coordinates": [4000, 5000]
          },
          {
            "type": "LineString",
            "properties": {"prop0": "value0", "prop1": 0},
            "arcs": [0]
          },
          {
            "type": "Polygon",
            "properties": {
              "prop0": "value0",
              "prop1": {"this": "that"}
            },
            "arcs": [[1]]
          }
        ]
      }
    },
    "arcs": [
      [[4000, 0], [1999, 9999], [2000, -9999], [2000, 9999]],
      [[0, 0], [0, 9999], [2000, 0], [0, -9999], [-2000, 0]]
    ]
  }`;
const initialState = {
  topojsonEditorValue: storedTopojsonEditorValue || defaultTopojsonEditorValue ,
};

const topojsonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOPOJSON_EDITOR_VALUE:
      return {
        ...state,
        topojsonEditorValue: action.payload,
      };
    default:
      return state;
  }
};

export default topojsonReducer;


