import { SET_GEO_EDITOR_VALUE } from '../actions/GeoJsonAction';

const localStorageKey = 'geojson_diagram';

const storedGeoEditorValue = localStorage.getItem(localStorageKey);
const defaultGeoEditorValue =
  storedGeoEditorValue ||
  `{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "id": 1,
      "properties": {
        "ID": 0
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-90, 35],
            [-90, 30],
            [-85, 30],
            [-85, 35],
            [-90, 35]
          ]
        ]
      }
    }
  ]
}`;
const initialState = {
  geoEditorValue: storedGeoEditorValue || defaultGeoEditorValue,
};

const geojsonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GEO_EDITOR_VALUE:
      return {
        ...state,
        geoEditorValue: action.payload,
      };
    default:
      return state;
  }
};

export default geojsonReducer;


