import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'

import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'
import { generateBlockDiagram } from 'src/views/dashboard/DashboardFunctions'

const BlockDiagram = ({
  panZoomRef,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let editorValue = useSelector((state) => state.blockEditor.blockEditorValue)
  const [initial, setInitial] = useState(true)
  const [blockDiagramImage, setBlockDiagramImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')

  axios.defaults.withCredentials = true

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {

    console.log('Editor Value:', editorValue);

    const blockImage = async () => {
      try {
        if(!editorValue.trim()){
          setBlockDiagramImage('')
          setPreviousCode('')
          return
        }
        if (previousCode.trim() === editorValue.trim()) return
        setPreviousCode(editorValue)
        setOpenSnackbarDiagram(true)
        const outputImage = await generateBlockDiagram(editorValue)
        if (outputImage != null && !outputImage.includes('failed to compile')) {
          setBlockDiagramImage(outputImage)
          localStorage.setItem('Block_IMG', outputImage)
          setLoading(false)
          setOpenSnackbarDiagram(false)
        } else {
          setLoading(false)
          setOpenSnackbar(true)
          setSnackbarMessage('Compilation failed. Invalid input format')
          setSnackbarSeverity('error')
          setOpenSnackbarDiagram(false)
        }
      } catch (error) {
        console.error('Error generating block diagram:', error)
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
      }
    }
    if (initial) {
      blockImage()
      setInitial(false)
    }

    if (!initial) {
      const intervalId = setInterval(blockImage, 4000)
      return () => clearInterval(intervalId)
    }
    localStorage.setItem('selectedMode', CONSTANTS.BLOCK)
  }, [editorValue, previousCode, initial])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
        <div className="alignMiddle">
  {loading ? (
    <div className="alignMiddle">
      <CircularProgress />
      Executing...
    </div>
  ) : (
    blockDiagramImage ? (
      <img
        src={`data:image/png;base64,${blockDiagramImage}`}
        alt="Block Diagram"
        className="image-style"
      />
    ) : (
      <div style={{ color: 'gray', fontSize: '24px' }}></div>
    )
  )}
</div>
        </PanZoom>
      </MapContainer>
    </div>
  )
}
export default BlockDiagram;
