import { SET_ASCII_EDITOR_VALUE } from "../actions/AsciiAction"


const localStorageKey = 'ascii_diagram'

const storedAsciiEditorValue = localStorage.getItem(localStorageKey)

const defaultAsciiEditorValue =
  storedAsciiEditorValue ||
  '+---------+\n|  Hello  |\n|  Ditaa  |\n+---------+'

const initialState = {
  asciiEditorValue: storedAsciiEditorValue || defaultAsciiEditorValue,
}

const asciiEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASCII_EDITOR_VALUE:
      return {
        ...state,

        asciiEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default asciiEditorReducer;
