import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as topojson from 'topojson-client';
import { useSelector } from 'react-redux';

const TopoMapView = () => {
  const editorValue = useSelector((state) => state.topoEditor.topojsonEditorValue);
  const [geojsonData, setGeojsonData] = useState(null);
  const [error, setError] = useState(''); // Error handling

  useEffect(() => {
    if (!editorValue || editorValue.trim() === '') {
      setGeojsonData(null);
      setError('Editor is empty.');
      return;
    }

    try {
      const parsedTopojsonData = JSON.parse(editorValue); 
      
      const geojson = topojson.feature(parsedTopojsonData, parsedTopojsonData.objects.example);
      setGeojsonData(geojson);
      setError(''); 
    } catch (err) {
      setError('Invalid TopoJSON format. Please fix the syntax.');
    }
  }, [editorValue]);

  // Custom Hook to Fit Map to Features
  const FitBounds = ({ geojsonData }) => {
    const map = useMap();
    useEffect(() => {
      if (geojsonData && map) {
        // Get bounds of the GeoJSON features (focus on the points)
        const bounds = L.geoJSON(geojsonData).getBounds();
        if (bounds.isValid()) {
          map.fitBounds(bounds, { padding: [100, 120] }); // Adjust padding as needed
        }
      }
    }, [geojsonData, map]);

    return null;
  };

  return (
    <div id="topoMapId">
      {error && <p style={{ color: 'red' }}>⚠️ {error}</p>}

      <MapContainer  center={[51.505, -0.09]} 
        zoom={2} 
        style={{ marginTop:"20px", height: "80vh", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {geojsonData && (
          <>
            <GeoJSON
              data={geojsonData}
              onEachFeature={(feature, layer) => {
                if (feature.properties) {
                  // Attach popup to each feature
                  layer.bindPopup(`<b>Properties:</b> ${JSON.stringify(feature.properties)}`);
                }
              }}
            />
            <FitBounds geojsonData={geojsonData} /> {/* Add FitBounds component */}
          </>
        )}
      </MapContainer>
    </div>
  );
};

export default TopoMapView;
