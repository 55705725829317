import { SET_BLOCK_EDITOR_VALUE } from "../actions/BlockEditorAction"

const localStorageKey = 'block_diagram'

const storedBlockEditorValue = localStorage.getItem(localStorageKey)

const defaultBlockEditorValue =
  storedBlockEditorValue ||
  'diagram admin {\n top_page -> config -> config_edit -> config_confirm -> top_page;\n}'
const initialState = {
  blockEditorValue:
  storedBlockEditorValue|| defaultBlockEditorValue,
}

const blockEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOCK_EDITOR_VALUE:
      return {
        ...state,

        blockEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default blockEditorReducer
