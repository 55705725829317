import { SET_ACT_EDITOR_VALUE } from "../actions/ActEditorAction"

const localStorageKey = 'act_diagram'

const storedActEditorValue = localStorage.getItem(localStorageKey)

const defaultActEditorValue =
  storedActEditorValue ||
  'diagram {\n   A -> B -> C;\n   lane you {\n    A; B;\n    }\n   lane me {\n    C;\n    }\n}\n'
const initialState = {
  actEditorValue:
  storedActEditorValue|| defaultActEditorValue,
}

const actEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACT_EDITOR_VALUE:
      return {
        ...state,

        actEditorValue: action.payload,
      }

    default:
      return state
  }
}

export default actEditorReducer
