import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import { useSelector } from 'react-redux';

const GeoMapView = () => {
  const editorValue = useSelector((state) => state.geoEditor.geoEditorValue);
  const [geojsonData, setGeojsonData] = useState(null);
  const [error, setError] = useState('');
  const mapRef = useRef(null); // Store map reference

  useEffect(() => {
    if (!editorValue || editorValue.trim() === '') {
      setGeojsonData(null);
      setError('Editor is empty.');
      return;
    }

    try {
      const parsedData = JSON.parse(editorValue);
      setGeojsonData(parsedData);
      setError('');
    } catch (err) {
      setError('Invalid GeoJSON format. Please fix the syntax.');
    }
  }, [editorValue]);

  // ✅ Custom Hook to Fit Map to Features
  const FitBounds = ({ geojsonData }) => {
    const map = useMap();
    useEffect(() => {
      if (geojsonData && map) {
        const bounds = L.geoJSON(geojsonData).getBounds();
        if (bounds.isValid()) {
          map.fitBounds(bounds, { padding: [120, 200] });
        }
      }
    }, [geojsonData, map]);
    return null;
  };

  // ✅ Customize Points & Lines
  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, {
      radius: 8,
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    }).bindPopup(`<b>Point:</b> ${latlng.lat}, ${latlng.lng}`);
  };

  return (
    <div id='geoMapId'> 
      {error && <p style={{ color: 'red' }}>⚠️ {error}</p>}

      <MapContainer 
        center={[51.505, -0.09]} 
        zoom={2} 
        style={{ height: "90vh", width: "100%" }}
        whenCreated={(map) => (mapRef.current = map)}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {geojsonData && (
          <>
            <GeoJSON data={geojsonData} pointToLayer={pointToLayer} />
            <FitBounds geojsonData={geojsonData} />
          </>
        )}
      </MapContainer>
    </div>
  );
};

export default GeoMapView;